import { FC, lazy } from 'react';
import './styles/HomepageSliderV3.scss';
import WithRtl from '../../../shared/WithRtl';
import QuickActions from '../QuickActions/QuickActions';
import classnames from 'classnames';

const RTL = lazy(() => import('./HomepageSliderV3Rtl'));

export type HomepageSliderProps = {
  tracingUrl?: string;
};

const HomepageSliderV3: FC<HomepageSliderProps> = ({ tracingUrl }) => {
  return (
    <>
      <WithRtl lazyElement={RTL} />
      <div className={classnames('homepageSliderGallery')}>
        <div className="quick-parent">
          <QuickActions tracingUrlDynamic={tracingUrl} />
        </div>
      </div>
    </>
  );
};
export default HomepageSliderV3;
